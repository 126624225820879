var shieldImg = require('../../../Images/shield.svg');
var bondImg = require('../../../Images/bond-note.svg');
import './wip.styles.scss';
var demoCtrl = /** @class */ (function () {
    function demoCtrl($timeout, $state) {
        this.$timeout = $timeout;
        this.$state = $state;
        this.demotext = 'This page was built with TypeScript!';
        this.imgUrl = shieldImg.default;
        this.$timeout = $timeout;
        this.$state = $state;
    }
    demoCtrl.prototype.updateText = function () {
        var _this = this;
        return this.$timeout(function () {
            _this.demotext = 'Content has been updated!';
            _this.imgUrl = bondImg.default;
        }, 3000);
    };
    demoCtrl.prototype.goHome = function () {
        this.$state.go('App.Home');
    };
    demoCtrl.prototype.$onInit = function () {
        window.location.href = "https://www.alphastarcm.com";
        //this.updateText();
    };
    demoCtrl.$inject = ['$timeout', '$state'];
    return demoCtrl;
}());
var demoComponent = {
    template: require('./wip.template.html'),
    bindings: {},
    controller: demoCtrl
};
export default demoComponent;

var heroController = /** @class */ (function () {
    function heroController() {
    }
    heroController.prototype.$onInit = function () { };
    return heroController;
}());
var heroComponent = {
    template: require('./hero.template.html'),
    bindings: {},
    controller: heroController
};
export default heroComponent;

var contactController = /** @class */ (function () {
    function contactController($http, emailService) {
        this.$http = $http;
        this.emailService = emailService;
        //this.toast = toast;
        this.ui = {
            sending: false,
            sent: false
        };
        this.formFields = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };
        this.toastMessage = '';
        this.toastClass = '';
        this.emailService = emailService;
        this.$http = $http;
    }
    contactController.prototype.buildMessage = function (formFields) {
        var message = 'Name: ' + formFields.firstName + ' ' + formFields.lastName + '\n';
        message += 'Email: ' + formFields.email + '\n';
        message += 'Message:\n' + formFields.message;
        return message;
    };
    contactController.prototype.sanitizeForm = function (form) {
        form.$setUntouched();
        this.formFields = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };
    };
    contactController.prototype.submitContactForm = function (formFields, form) {
        var _this = this;
        return this.emailService.sendEmail(formFields)
            .then(function () {
            _this.toastMessage = 'Thank you for contacting us. A representative will reach out to you shortly.';
            _this.toastClass = 'text-green-500';
            _this.ui.sent = true;
            _this.sanitizeForm(form);
        })
            .catch(function (error) {
            _this.toastMessage = 'Sorry, we could not send your message. Please try again later.';
            _this.toastClass = 'text-red-500';
            console.log(error);
        })
            .finally(function () {
            _this.ui.sending = false;
        });
    };
    contactController.prototype.$onInit = function () {
    };
    contactController.$inject = ['$http', 'emailService'];
    return contactController;
}());
var contactComponent = {
    template: require('../Contact/contact.template.html'),
    bindings: {},
    controller: contactController
};
export default contactComponent;

var howController = /** @class */ (function () {
    function howController() {
        this.infoArray = [
            'The Strategy is tailored to the specific characteristics of the individual investor.',
            'It dynamically weights each asset in the mix based on current market conditions',
            'It incorporates guaranteed income from annuities.'
        ];
    }
    howController.prototype.$onInit = function () {
    };
    return howController;
}());
var howComponent = {
    template: require('./how.template.html'),
    bindings: {},
    controller: howController
};
export default howComponent;

var nodeEnv = process.env.NODE_ENV !== 'production'
    ? {
        name: 'development',
        development: true
    }
    : {
        name: 'production',
        development: false
    };
export default nodeEnv;

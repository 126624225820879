// ES6 class
var emailService = /** @class */ (function () {
    function emailService($http, apiConfig) {
        this.$http = $http;
        this.apiConfig = apiConfig;
        this.$http = $http;
        this.apiConfig = apiConfig;
    }
    emailService.prototype.sendEmail = function (email) {
        return this.$http.post(this.apiConfig.POSTUrl, email, {
            ignoreLoadingBar: true,
            headers: { "x-api-key": this.apiConfig.APIKey }
        });
    };
    emailService.$inject = ['$http', 'apiConfig'];
    return emailService;
}());
export default emailService;

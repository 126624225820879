var stockImg = require('../../../Images/stocks.svg');
var bondImg = require('../../../Images/bond-note.svg');
var shieldImg = require('../../../Images/shield.svg');
var whatController = /** @class */ (function () {
    function whatController() {
        this.infoArray = [
            {
                imgUrl: stockImg.default,
                title: 'Stock for growth'
            },
            {
                imgUrl: bondImg.default,
                title: 'Bonds for diversification'
            },
            {
                imgUrl: shieldImg.default,
                title: 'Annuities for guaranteed retirement income'
            }
        ];
    }
    whatController.prototype.$onInit = function () { };
    return whatController;
}());
var whatComponent = {
    template: require('./what.template.html'),
    bindings: {},
    controller: whatController
};
export default whatComponent;

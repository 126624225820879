import angular from 'angular';
import routing from './home.config';
import homeComponent from './home.component';
import hero from '../../Components/Hero/hero.module';
import what from '../../Components/What/what.module';
import how from '../../Components/How/how.module';
import contact from '../../Components/Contact/contact.module';
import './home.styles.scss';
export default angular.module('app.home', [hero, what, how, contact])
    .config(routing)
    .component('homeComponent', homeComponent)
    .name;

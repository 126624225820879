var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
//NPM import
import angular from 'angular';
import uirouter from '@uirouter/angularjs/';
import duScroll from 'angular-scroll';
import loadingBar from 'angular-loading-bar';
// import loadingBarService from 'cfp.loadingBar';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
//Config import
import routing from './app.config';
import loadingBarConfig from './Config/angular-loading-bar.config';
//Module import
import home from './States/Home/home.module';
import wip from './States/WIP/wip.module';
//Style import
import './app.styles.scss';
var libDependencies = [uirouter, ngAnimate, ngSanitize, duScroll, loadingBar];
var appDependencies = [home, wip];
var doc = document;
var module = 'App';
export var app = angular
    .module(module, __spreadArrays(libDependencies, appDependencies))
    .config(routing)
    .config(loadingBarConfig);
export default app.name; //TODO: use this parent app to register other services in their own file?
angular.element(doc).ready(function () { return angular.bootstrap(doc, [module], { strictDi: true }); });

// controller
var homeController = /** @class */ (function () {
    function homeController($document, $window, cfpLoadingBar, $timeout) {
        this.$document = $document;
        this.$window = $window;
        this.cfpLoadingBar = cfpLoadingBar;
        this.$timeout = $timeout;
        this.nav = [
            {
                title: 'What it is',
                url: 'whatItIs'
            },
            {
                title: 'How it works',
                url: 'howItWorks'
            },
            {
                title: 'Get in touch',
                url: 'contactUs'
            }
        ];
        this.mainNav = {
            isOpen: false
        };
        this.$document = $document;
        this.$window = $window;
        this.cfpLoadingBar = cfpLoadingBar;
        this.$timeout = $timeout;
    }
    homeController.prototype.showHideHeader = function () {
        var _window = this.$window;
        var prevScrollPos = _window.pageYOffset;
        var header = document.getElementById('headerDesktop');
        _window.onscroll = function () {
            var currentScrollPos = _window.pageYOffset;
            if (!header)
                throw console.error('Header element not found.');
            if (prevScrollPos > currentScrollPos)
                header.style.transform = 'translateY(0)';
            else
                header.style.transform = 'translateY(-100%)';
            prevScrollPos = currentScrollPos;
        };
    };
    homeController.prototype.scrollTop = function (top, duration) {
        this.$document.scrollTop(top, duration).then(function () {
            return console.log('Scrolled to top');
        });
    };
    homeController.prototype.toggleMobileNav = function (nav) {
        nav.isOpen = !nav.isOpen;
    };
    homeController.prototype.pseudoLoadingBar = function () {
        var _cfpLoadingBar = this.cfpLoadingBar;
        _cfpLoadingBar.start();
        this.$timeout(function () {
            _cfpLoadingBar.complete();
        }, 500);
    };
    homeController.prototype.$onInit = function () {
        this.showHideHeader();
        this.mainNav = {
            isOpen: false
        };
        this.pseudoLoadingBar();
    };
    homeController.$inject = ['$document', '$window', 'cfpLoadingBar', '$timeout'];
    return homeController;
}());
var homeComponent = {
    template: require('./home.template.html'),
    bindings: {},
    controller: homeController
};
export default homeComponent;

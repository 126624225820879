import nodeEnv from './nodeEnv.constant';
var configurationDev = {
    'POSTUrl': 'https://11h66hw35e.execute-api.us-east-1.amazonaws.com/stage/contactus',
    'APIKey': '7832d8fc-92dc-4c7a-ae5a-8f08896cb146',
    'ProjectUrl': 'qa.myempowermentstrategy.com',
    'Test': 'This is dev config'
};
var configurationProd = {
    'POSTUrl': 'https://11h66hw35e.execute-api.us-east-1.amazonaws.com/stage/contactus',
    'APIKey': '7832d8fc-92dc-4c7a-ae5a-8f08896cb146',
    'ProjectUrl': 'qa.myempowermentstrategy.com',
    'Test': 'This is production config'
};
var configuration = {};
if (nodeEnv.development)
    configuration = configurationDev;
else
    configuration = configurationProd;
export default configuration;
